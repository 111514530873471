import Vue from 'vue';
import Router from 'vue-router';
import { getLangFromBrowser } from '@/i18n/index';

Vue.use(Router);

/**
 * 重写路由的push方法 解决 Navigating to current location (xxxx) is not allowed问题
 */
const routerPush = Router.prototype.push;
const routerReplace = Router.prototype.replace;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => error);
};
Router.prototype.replace = function replace(location) {
    return routerReplace.call(this, location).catch((error) => error);
};

const routes = [
    {
        path: '/basic/:customerId?',
        name: 'login',
        component: () => import('@pc/views/login/index.vue'),
        meta: {
            hiddenFields: ['sidebar', 'myorder', 'service'],
        },
    },
    {
        path: '/list/:customerId?',
        name: 'list',
        component: () => import('@pc/views/list/index.vue'),
        meta: {
            hiddenFields: ['sidebar', 'myorder', 'service'],
        },
    },
    {
        path: '/choose/:customerId?',
        name: 'chooseType',
        component: () => import('@pc/views/choose/index.vue'),
        meta: {
            hiddenFields: ['sidebar', 'service'],
        },
    },
    {
        path: '/change-ukey/:customerId?',
        name: 'changeUkey',
        component: () => import('@pc/views/change/index.vue'),
        meta: {
            hiddenFields: ['sidebar', 'service'],
        },
    },
    {
        path: '/transfer/:id',
        name: 'transfer',
        component: () => import('@pc/views/transfer/index.vue'),
        meta: {
            hiddenFields: ['sidebar', 'myorder', 'service'],
        },
    },
    {
        path: '/step',
        name: 'step',
        component: () => import('@pc/views/step/step.vue'),
        children: [
            {
                path: 'auth/:id',
                name: 'auth',
                component: () => import('@pc/views/auth/auth.vue'),
            },
            {
                path: 'buy-info/:id',
                name: 'buyInfo',
                component: () => import('@pc/views/buy-info/buy-info.vue'),
            },
            {
                path: 'pay/:id',
                name: 'pay',
                alias: '/pay/:id',
                component: () => import('@pc/views/pay/pay.vue'),
            },
            {
                path: 'pay-failed/:id',
                name: 'payFailed',
                component: () => import('@pc/views/pay/pay-failed.vue'),
            },
            {
                path: 'delivery/:id',
                name: 'delivery',
                component: () => import('@pc/views/delivery/delivery.vue'),
            },
            {
                path: 'seal-make/:id',
                name: 'sealMake',
                component: () => import('@pc/views/seal-make/seal-make.vue'),
            },
            {
                path: 'invoice/:id',
                name: 'invoice',
                component: () => import('@pc/views/invoice/invoice.vue'),
            },
            {
                path: 'apply-invoice/:id',
                name: 'applyInvoice',
                alias: '/apply-invoice/:id',
                component: () => import('@pc/views/invoice/apply-invoice.vue'),
            },
            {
                path: 'mobile-seal-make/:id',
                name: 'mobileSealMake',
                component: () => import('@pc/views/mobile-seal-make/index.vue'),
            },
        ],
    },
    {
        path: '/link-expired',
        name: 'linkExpired',
        component: () => import('@pc/views/exception/link-expired.vue'),
        meta: {
            hiddenFields: ['sidebar', 'myorder', 'service', 'exit'],
        },
    },
    {
        path: '*',
        component: () => import('@pc/views/exception/404.vue'),
        meta: {
            hiddenFields: ['sidebar', 'myorder', 'service'],
        },
    },
    // UKey 续期模块
    {
        path: '/renewal',
        name: 'renewal',
        redirect: '/renewal/login',
        component: () => import('@pc/views/renewal/index.vue'),
        children: [
            {
                path: 'login',
                name: 'renewalLogin',
                component: () => import('@pc/views/renewal/login/index.vue'),
                meta: {
                    hiddenFields: ['sidebar', 'myorder', 'renewal'],
                    modules: 'renewal',
                },
            },
            {
                path: 'detail/:deviceId',
                name: 'renewalDetail',
                component: () => import('@pc/views/renewal/detail/index.vue'),
                meta: {
                    hiddenFields: ['sidebar', 'myorder', 'renewal'],
                    modules: 'renewal',
                },
            },
            {
                path: 'list/:deviceId',
                name: 'renewalList',
                component: () => import('@pc/views/renewal/list/index.vue'),
                meta: {
                    hiddenFields: ['sidebar', 'myorder', 'renewal'],
                    modules: 'renewal',
                },
            },
            {
                path: 'invoice/:deviceId/:renewalId',
                name: 'renewalInvoice',
                component: () => import('@pc/views/renewal/invoice/index.vue'),
                meta: {
                    hiddenFields: ['sidebar', 'myorder', 'renewal'],
                    modules: 'renewal',
                },
            },
        ],
    },
];

const router = new Router({
    mode: 'history',
    base: `${window.BASE_PATH}`,
    routes,
});

router.beforeEach((to, from, next) => {
    // 私有云链接keep qurey (bizId, uid)
    if (to.query.bizId) {
        next();
    } else if (from.query.bizId) {
        next({ ...to, query: { ...to.query, bizId: from.query.bizId, uid: from.query.uid } });
    } else if (to.query.source) {
        next();
    } else if (!to.query.lang) {
        next({
            ...to,
            query: { ...to.query, source: from.query.source, lang: from.query.lang || getLangFromBrowser() },
        });
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    document.getElementById('app').scrollTop = 0;
});

export default router;
