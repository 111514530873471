const Config = {
    /**
     * 系统弹窗规格
     */
    DIALOG_SIZE: {
        mini: '280px',
        small: '430px',
        medium: '630px',
        large: '800px',
        massive: '1000px',
    },
};
export default Config;
