<template>
<div class="process-side-bar">
    <el-steps direction="vertical" :active="activeIndex">
        <el-step v-for="(item, index) in stepList" :key="index" :title="item">
            <div v-if="activeIndex === index + 1" slot="icon" class="active-step-circle">
                <div class="point"></div>
            </div>
            <div v-else slot="icon" class="step-circle"></div>
        </el-step>
    </el-steps>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            stepList: [],
        };
    },
    computed: {
        ...mapGetters(['order', 'orderConfig']),
        activeIndex() {
            const pageName = this.$route.name;
            // 私有云订单且 pay 为 false（无需支付），不展示已支付阶段
            if (Object.is(this.order.source, 'PRIVATE')) {
                if (this.order.extension && Object.is(this.order.extension.pay, false)) {
                    if (Object.is(pageName, 'mobileSealMake')) {
                        return 3;
                    } else {
                        if (Object.is(this.orderConfig?.makeType, 'QYS') && Object.is(pageName, 'sealMake')) {
                            return 2;
                        } else if (Object.is(pageName, 'delivery')) {
                            return 3;
                        } else if (Object.is(pageName, 'sealMake')) {
                            if (this.order.orderType === 'UKEY_CHANGE') {
                                return 3;
                            }
                            return 4;
                        }
                    }
                }
            }
            const map = new Map([
                [
                    {
                        value: Object.is(this.order.auditStatus, 'CANCELED'),
                    },
                    0,
                ],
                [
                    {
                        value: Object.is(pageName, 'auth'),
                    },
                    1,
                ],
                [
                    {
                        value: Object.is(pageName, 'buyInfo'),
                    },
                    2,
                ],
                [
                    {
                        value: Object.is(pageName, 'pay') || Object.is(pageName, 'payFailed'),
                    },
                    3,
                ],
                [
                    {
                        value: Object.is(pageName, 'delivery') || Object.is(pageName, 'mobileSealMake') || (Object.is(pageName, 'sealMake') && this.order.orderType === 'UKEY_CHANGE'),
                    },
                    4,
                ],
                [
                    {
                        value: Object.is(pageName, 'sealMake'),
                    },
                    5,
                ],
                [
                    {
                        value: Object.is(pageName, 'invoice') || Object.is(pageName, 'applyInvoice'),
                    },
                    6,
                ],
            ]);
            const action = [...map].filter(([key, value]) => Object.is(key.value, true));
            for (const [key, value] of action) {
                return value;
            }
        },
    },
    watch: {
        order: {
            handler(newVal) {
                if (this.$route.name === 'buyInfo') {
                    this.handleStepList(this.$route.query.makeType);
                } else {
                    this.handleStepList(newVal.orderType);
                }
            },
            immediate: true,
        },
        $route: {
            handler(newVal) {
                if (newVal.name === 'buyInfo') {
                    this.handleStepList(newVal.query.makeType);
                }
            },
            immediate: true,
        },
    },
    created() {},
    mounted() {},
    methods: {
        handleStepList(type) {
            let arr = [];
            if (Object.is(type, 'MOBILE_SEAL')) {
                arr = [$t('independent.身份认证'), $t('independent.提交订单'), $t('independent.订单支付'), $t('independent.印章激活')];
                if (Object.is(this.order.source, 'PRIVATE')) {
                    if (this.order.extension && Object.is(this.order.extension.pay, false)) {
                        arr = [$t('independent.身份认证'), $t('independent.提交订单'), $t('independent.印章激活')];
                    }
                }
            } else {
                if (Object.is(this.orderConfig?.makeType, 'QYS')) {
                    arr = [$t('independent.身份认证'), $t('independent.提交订单'), $t('independent.订单支付'), $t('independent.等待寄送')];
                } else {
                    arr = [$t('independent.身份认证'), $t('independent.提交订单'), $t('independent.订单支付'), $t('independent.等待寄送'), $t('independent.印章制作')];
                }
                if (Object.is(this.order.source, 'PRIVATE')) {
                    if (this.order.extension && Object.is(this.order.extension.pay, false) && this.order.auditStatus !== 'TO_BE_PAY') {
                        if (Object.is(this.orderConfig?.makeType, 'QYS')) {
                            arr = [$t('independent.身份认证'), $t('independent.提交订单'), $t('independent.等待寄送')];
                        } else {
                            arr = [$t('independent.身份认证'), $t('independent.提交订单'), $t('independent.等待寄送'), $t('independent.印章制作')];
                        }
                    }
                }
                if (this.order.orderType === 'UKEY_CHANGE') {
                    arr = [$t('independent.身份认证'), $t('independent.提交订单'), $t('independent.订单支付'), $t('independent.印章制作')];
                    if (Object.is(this.order.source, 'PRIVATE')) {
                        if (this.order.extension && Object.is(this.order.extension.pay, false)) {
                            arr = [$t('independent.身份认证'), $t('independent.提交订单'), $t('independent.印章制作')];
                        }
                    }
                }
            }
            this.stepList = arr;
        },
    },
};
</script>

<style scoped lang="less">
.process-side-bar {
    width: 190px;
    min-width: 190px;
    height: 328px;
    background: @color-white;
    margin-right: 16px;
    padding: 40px 44px;
    border-radius: @border-radius-medium;
    .step-circle {
        background-color: @color-fill-4;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: @border-radius-circle;
    }
    .active-step-circle {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: @border-radius-circle;
        background: rgba(36, 137, 242, 0.2);
        position: relative;
        margin-top: 10px;
        .point {
            background-color: @primary-6;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: @border-radius-circle;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    /deep/ .el-step {
        flex: 1 1 auto;

        .el-step__head {
            padding-top: 4px;
        }
        .el-step__title {
            color: @color-text-2;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            &.is-finish {
                color: @color-text-5;
            }
        }
        &.is-vertical {
            .el-step__line {
                width: 0;
                border-left: 1px dashed @color-border-2;
                background: @color-white;
                bottom: -3px;
                top: 8px;
                margin-left: 1px;
            }
        }
        .is-finish {
            color: @color-text-5;
            .el-step__line-inner {
                border-width: 0 !important;
            }
            .el-step__icon {
                // height: 30px;
                .step-circle {
                    background: @success-6;
                    // border: 12px solid rgba(36, 137,242, 0.2);
                }
            }
        }
    }
    /deep/ .el-step__icon {
        height: 8px;
        &.is-text {
            border-width: 0;
        }
    }
}
</style>
