import Vue from 'vue';
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import './theme/index.css';

Vue.use(ElementUI);

// ------------------- 样式覆盖 ----------------------------
const reqLess = require.context('./', false, /\.less$/);
reqLess.keys().forEach(reqLess);
// --------------------------------------------------------

// ------------------ 二次封装组件覆盖 ----------------------
const reqModifierComponents = require.context('./', false, /\.vue$/);
reqModifierComponents.keys().forEach((path) => {
    const name = path.replace(/.+\/(.*?)\.vue/, '$1');
    const component = reqModifierComponents(path).default;

    Vue.component(name, component);
});
// --------------------------------------------------------
