<template>
<div id="app">
    <header-bar v-if="!hiddenFields.includes('header')" :hidden-fields="hiddenFields"></header-bar>
    <div
        :class="[
            'app-frame-content',
            {
                'app-frame-content-width': moduleSource === 'renewal',
            },
        ]"
    >
        <process-side-bar v-if="!hiddenFields.includes('sidebar')"></process-side-bar>
        <div
            :class="[
                moduleSource === 'renewal' ? 'app-main-content-renewal' : 'app-main-content',
                {
                    'app-main-content-mb': currentRouteName === 'buyInfo',
                },
            ]"
        >
            <router-view v-if="!isPrivateSource || hasSetCookie" />
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            hasSetCookie: false,
        };
    },
    computed: {
        ...mapGetters(['order', 'orderConfig', 'moduleSource']),
        isPrivateSource() {
            return !!this.$route.query.bizId;
        },
        hiddenFields() {
            return this.$route.meta.hiddenFields || [];
        },
        orderId() {
            return this.$route.params.id || '';
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
    watch: {
        orderId: {
            async handler(nv) {
                if (this.isPrivateSource) {
                    await this.setCookie();
                }
                this.getOrder(nv);
            },
            immediate: true,
        },
        'order.auditStatus': {
            handler(nv) {
                const map = new Map([
                    [
                        {
                            value: nv === 'TO_BE_SIGNED',
                        },
                        {
                            routeName: 'auth',
                        },
                    ],
                    [
                        {
                            value: nv === 'TO_BE_CHECKED',
                        },
                        {
                            routeName: 'buyInfo',
                        },
                    ],
                    [
                        {
                            value: nv === 'TO_BE_PAY',
                        },
                        {
                            routeName: 'pay',
                        },
                    ],
                    [
                        {
                            value: nv === 'TO_BE_SEND',
                        },
                        {
                            routeName: 'delivery',
                        },
                    ],
                    [
                        {
                            value: nv === 'SENDING',
                        },
                        {
                            routeName: 'delivery',
                        },
                    ],
                    [
                        {
                            value: nv === 'TO_BE_MADE' && ['UKEY', 'UKEY_CHANGE'].includes(this.order.orderType),
                        },
                        {
                            routeName: 'sealMake',
                        },
                    ],
                    [
                        {
                            value: nv === 'TO_BE_WRITE',
                        },
                        {
                            routeName: 'sealMake',
                        },
                    ],
                    [
                        {
                            value: nv === 'TO_BE_MADE' && this.order.orderType === 'MOBILE_SEAL',
                        },
                        {
                            routeName: 'mobileSealMake',
                        },
                    ],
                    [
                        {
                            value: nv === 'FINISHED',
                        },
                        {
                            routeName: 'invoice',
                        },
                    ],
                    [
                        {
                            value: nv === 'INVALID',
                        },
                        {
                            routeName: 'payFailed',
                        },
                    ],
                    [
                        {
                            value: nv === 'CANCELED',
                        },
                        {
                            routeName: 'payFailed',
                        },
                    ],
                ]);
                const action = [...map].filter(([key, value]) => Object.is(key.value, true));
                for (const [key, value] of action) {
                    if (this.orderConfig?.makeType === 'QYS' && nv === 'TO_BE_MADE') {
                        // 总部制作的待制作订单跳转到待寄送页面
                        this.$router.replace({
                            name: 'delivery',
                            params: { id: this.order.id },
                            query: { ...this.$route.query },
                        });
                    } else {
                        this.$router.replace({
                            name: value.routeName,
                            query: { ...this.$route.query },
                            params: { id: this.order.id },
                        });
                    }
                }
            },
            immediate: true,
        },
        $route: {
            handler(newVal) {
                this.$store.dispatch('getModuleSource', newVal.meta.modules || '');
            },
            deep: true,
        },
    },
    methods: {
        async setCookie() {
            try {
                const { bizId, uid } = this.$route.query;
                const aesEncryptBizId = encodeURIComponent(this.$_utils.aesEncrypt(bizId));
                const aesEncryptUid = encodeURIComponent(this.$_utils.aesEncrypt(uid));
                await this.$qHttp.postJson('/cookie/set', { name: 'biz-token', token: aesEncryptBizId });
                await this.$qHttp.postJson('/cookie/set', { name: 'biz-uid', token: aesEncryptUid });
            } catch (error) {
                console.log(error);
            } finally {
                this.hasSetCookie = true;
            }
        },

        async getOrder(id) {
            try {
                await this.$store.dispatch('initOrder', id);
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>

<style lang="less">
@import '../assets/css/normalize.css';
@import '../assets/fonts/iconfont.css';
#app {
    height: 100%;
    overflow: auto;
    background: @color-gray-8;
}
</style>
<style scoped lang="less">
.app-frame-content {
    display: flex;
    width: 1206px;
    margin: 72px auto 20px;
    box-sizing: border-box;
    @media (max-width: 1440px) {
        width: 1100px;
    }
    &-width {
        width: 100%;
    }
}
.app-main-content {
    background: @color-white;
    border-radius: @border-radius-large;
    flex: 1;
    padding: 40px 72px;
    min-height: 88vh;
    box-sizing: border-box;
    &-height {
        min-height: 88vh;
    }
    &-mb {
        margin-bottom: 64px;
    }
}
.app-main-content-renewal {
    background: none;
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 40px 72px;
    box-sizing: border-box;
}
</style>
