<template>
<div class="header-bar">
    <div class="header-bar__left">
        <el-popconfirm popper-class="client-back-popper" v-if="showBack" :title="backTitle" :confirm-button-text="$t('independent.确定')" :cancel-button-text="$t('independent.取消')" @confirm="exit">
            <span slot="reference" class="header-bar__left--back">
                <i class="iconfont icon-back"></i>
                {{$t('independent.退出')}}
            </span>
        </el-popconfirm>
    </div>
    <div class="header-bar__center">{{ headTitle }}</div>
    <div class="header-bar__right">
        <div v-if="!orderConfig.hideMyOrder && !hiddenFields.includes('myorder')" class="header-bar__right--order" @click="openOrderPage">
            <i class="iconfont icon-remark-o-"></i>
            <span>{{$t('independent.我的订单')}}</span>
        </div>

        <el-popover v-if="!hiddenFields.includes('service') && customerContact" placement="top-start" trigger="hover" popper-class="client-service-popper">
            <div class="header-bar__right--popper">
                <span class="header-bar__right--popper--title">{{$t('independent.联系客服')}}</span>
                <span class="header-bar__right--popper--contact">{{ customerContact }}</span>
                <span class="header-bar__right--popper--time">{{$t('independent.周一至周五 9:00-18:00')}}</span>
            </div>
            <div slot="reference" class="header-bar__right--text">
                <i class="iconfont icon-customerservice"></i>
                <span>{{$t('independent.联系客服')}}</span>
            </div>
        </el-popover>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ToolsService from '@/service/tools';

export default {
    props: {
        hiddenFields: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapGetters(['order', 'orderConfig', 'customerContact', 'moduleSource']),
        mobile() {
            return this.$route.query.mobile || this.$store.getters.mobile;
        },
        customerId() {
            return this.$route.params.customerId || this.$store.getters.customerId;
        },
        headTitle() {
            const routeName = this.$route.name;
            const orderType = routeName === 'changeUkey' ? 'UKEY_CHANGE' : this.order.orderType;
            if (this.moduleSource === 'renewal') {
                return $t('independent.USB Key检测');
            } else {
                return new Map([
                    ['UKEY', $t('independent.UKey申请')],
                    ['UKEY_CHANGE', $t('independent.UKey变更')],
                    ['MOBILE_SEAL', $t('independent.移动签章申请')],
                    ['default', $t('independent.UKey与移动签章申请')],
                ]).get(orderType || 'default');
            }
        },
        showBack() {
            if (this.hiddenFields.includes('renewal')) {
                return !!ToolsService.getQueryString('source');
            }
            return !this.hiddenFields.includes('exit');
        },
        backTitle() {
            return this.hiddenFields.includes('renewal') ? $t('independent.确认退出当前检测与更新？') : $t('independent.确认退出当前申请？');
        },
    },
    methods: {
        exit() {
            if (this.hiddenFields.includes('renewal')) {
                if (!!ToolsService.getQueryString('source')) this.backIndex();
            } else {
                if (this.order.source === 'PRIVATE') {
                    window.location.replace(this.orderConfig.returnUrl);
                } else if (this.$route.name === 'login') {
                    this.backIndex();
                } else {
                    const customerId = this.customerId || this.order.customerId || '';
                    this.$router.replace({ name: 'login', params: customerId ? { customerId } : {} });
                }
            }
        },

        /** 返回官网 */
        backIndex() {
            const env = ['.me', '.net', '.cn', '.com'];
            const host = window.location.host;
            let currentEnv = host.substring(host.lastIndexOf('.'));
            if (!env.includes(currentEnv)) {
                currentEnv = '.me';
            }
            window.location.href = `https://www.qiyuesuo${currentEnv}/`;
        },

        /** 进入订单页面 */
        openOrderPage() {
            this.$router.replace({
                name: 'list',
                query: { mobile: this.mobile },
                params: this.customerId ? { customerId: this.customerId } : {},
            });
        },
    },
};
</script>

<style scoped lang="less">
.btn-old {
    margin-left: 20px;
}
.header-bar {
    width: 100%;
    padding: 15px 24px;
    box-shadow: 0px 2px 4px rgba(0, 19, 48, 0.06);
    background: @color-white;
    position: fixed;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__left {
        cursor: pointer;
        &--back {
            .qys-text-13;
            color: @color-text-3;
        }
    }
    &__center {
        .qys-text-bold-18;
        color: @color-text-5;
    }
    &__right {
        display: flex;
        align-items: center;
        &--text {
            .qys-text-13;
            color: @color-text-3;
            display: flex;
            align-items: center;
            margin-left: 21px;
            & > i {
                margin-right: 2px;
                font-size: 14px;
            }
        }
        &--order {
            .qys-text-13;
            color: @color-text-3;
            cursor: pointer;
            display: flex;
            align-items: center;
            & > i {
                margin-right: 2px;
                font-size: 14px;
            }
        }
        &--popper {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            &--title {
                .qys-text-16;
                color: @color-text-5;
            }
            &--contact {
                .qys-text-24;
                color: @primary-6;
                margin: 24px 0 16px;
            }
            &--time {
                .qys-text-12;
                color: @color-text-2;
            }
        }
    }
}
</style>
<style lang="less">
.client-back-popper {
    &.el-popover {
        padding: 5px 17px 17px;
        width: 229px;
    }
    .el-popconfirm__main {
        margin: 16px 0 12px;
    }
    .el-popconfirm__main {
        display: flex;
        align-items: flex-start;
        & > i {
            margin-top: 2px;
        }
    }
    .el-button--text {
        border: 1px solid @color-gray-10;
        padding: 2px 8px;
        color: @color-mobile-2;
        & > span {
            .qys-text-12;
        }
    }
    .el-button--primary {
        padding: 2px 8px;
        & > span {
            .qys-text-12;
        }
    }
}
.client-service-popper {
    &.el-popover {
        padding: 28px 38px;
    }
    .el-button--text {
        color: @primary-6;
    }
    .el-button--primary {
        background-color: @primary-6;
        border-color: @primary-6;
    }
}
</style>
