const BrowserUtils = {
    /**
     * 获取查询条件得值
     *
     * @param {*} paraName
     */
    getUrlParam: (...paraName) => {
        let url = document.location.toString();
        let arrObj = url.split('?');
        if (arrObj.length > 1) {
            let arrPara = arrObj[1].split('&');
            let arr;

            for (let i = 0; i < arrPara.length; i++) {
                arr = arrPara[i].split('=');
                if (arr != null && paraName.includes(arr[0]) && arr[1] === 'true') {
                    return arr[1];
                }
            }
            return '';
        }
        return '';
    },

    /**
     * 以下方法为浏览器检测
     * TODO: 需要随时更新
     */
    isFirefox: () => {
        return typeof InstallTrigger !== 'undefined';
    },

    isIE: () => {
        return navigator.userAgent.includes('MSIE') || !!document.documentMode;
    },

    isEdge: () => {
        return !BrowserUtils.isIE() && !!window.StyleMedia;
    },

    isChrome: (context = window) => {
        return !!context.chrome;
    },

    isSafari: () => {
        return (
            Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 ||
            navigator.userAgent.toLowerCase().includes('safari')
        );
    },

    isWeixin: () => {
        const ua = navigator.userAgent.toLowerCase();
        // 排除企业微信
        return ua.match(/MicroMessenger/i) === 'micromessenger' && ua.match(/wxwork/i) !== 'wxwork';
    },
};
export default BrowserUtils;
