import Vue from 'vue';

/**
 * 自动导入指定文件夹下的vue-sfc
 *
 * 允许的文件名规则为：
 * 1. your-component-name/index.vue
 * 2. your-component-name/your-component-name.vue
 * 其余的文件将不会被注册
 *
 * 组件名：
 * - 若在组件内部配置了componentName属性，则使用此属性的值（由于vue-class-component的一个bug，name属性在生产构建后会无法正确取到）
 * - 若未配置componentName属性，则取文件夹的名称（例如：your-component-name/index.vue，则组件名为your-component-name）
 */
const registerSFC = (context) => {
    context.keys().forEach((key) => {
        const sfc = context(key).default;

        const componentName = sfc.componentName;
        const folderName = key.replace(/^.\/(.*?)\/.*/, '$1');

        const finalName = componentName || folderName;

        Vue.component(finalName, sfc);
    });
};

registerSFC(require.context('./basic', true, /^\.\/(.*?)\/(index|\1)\.vue$/));
