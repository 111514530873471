var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(!_vm.hiddenFields.includes('header'))?_c('header-bar',{attrs:{"hidden-fields":_vm.hiddenFields}}):_vm._e(),_c('div',{class:[
            'app-frame-content',
            {
                'app-frame-content-width': _vm.moduleSource === 'renewal',
            },
        ]},[(!_vm.hiddenFields.includes('sidebar'))?_c('process-side-bar'):_vm._e(),_c('div',{class:[
                _vm.moduleSource === 'renewal' ? 'app-main-content-renewal' : 'app-main-content',
                {
                    'app-main-content-mb': _vm.currentRouteName === 'buyInfo',
                },
            ]},[(!_vm.isPrivateSource || _vm.hasSetCookie)?_c('router-view'):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }