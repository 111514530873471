import { validator } from '@conandmobile/vue-utils/validator';
import store from '@/store';
import { Apis } from '@/api';

const formatTypes = {
    HYPHEN: 'yyyy-MM-dd',
    VIRGULE: 'yyyy/MM/dd',
    Chinese: 'yyyy年MM月dd日',
};

const CommonUtils = {
    /**
     * 图片加载函数
     *
     * @param {*} event
     */
    successLoadImg(event) {
        let target = event.target || event.srcElement;
        let scale = target.parentElement.offsetWidth / target.parentElement.offsetHeight;
        let image = target;
        if (image.complete == true) {
            if (image.naturalWidth > image.naturalHeight * scale) {
                image.style.cssText = 'width: 100%;';
            } else {
                image.style.cssText = 'height: 100%;';
            }
        }
    },

    /**
     * 深拷贝
     *
     * @param {*} obj
     */
    deepClone(obj) {
        let str,
            newobj = obj.constructor === Array ? [] : {};
        if (typeof obj !== 'object') {
            return;
        } else if (window.JSON) {
            (str = JSON.stringify(obj)), //系列化对象
                (newobj = JSON.parse(str)); //还原
        } else {
            for (let i in obj) {
                newobj[i] = typeof obj[i] === 'object' ? cloneObj(obj[i]) : obj[i];
            }
        }
        return newobj;
    },

    /**
     * 树形结构转换为扁平数组
     *
     * @param {*} obj
     * @param tree
     */
    treeToList(tree) {
        let queen = [];
        let out = [];
        queen = queen.concat(tree);

        while (queen.length) {
            let first = queen.shift();
            if (first.children) {
                queen = queen.concat(first.children);
                delete first['children'];
            }

            out.push(first);
        }
        return out;
    },

    /**
     * 检查是否是空对象
     *
     * @param {*} obj
     */
    checkNullObject(obj) {
        return typeof obj === 'object' && JSON.stringify(obj) === '{}';
    },

    /**
     * getUid - 生成一个n为的随机数
     *
     *  @param {n} 生成位数
     * @param n
     * @returns {id}      di
     */
    getUid(n = 16) {
        let chars = [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            'a',
            'b',
            'c',
            'd',
            'e',
            'f',
            'g',
            'h',
            'i',
            'j',
            'k',
            'l',
            'm',
            'n',
            'o',
            'p',
            'q',
            'r',
            's',
            't',
            'u',
            'v',
            'w',
            'x',
            'y',
            'z',
        ];
        let result = '';
        for (let i = 0; i < n; i++) {
            let id = Math.ceil(Math.random() * chars.length);
            result += chars[id];
        }
        return result;
    },

    /**
     * 数组去重（支持含对象数组）
     *
     * @param {*} array 目标数组
     */
    unique(array) {
        let obj = {};
        return array.filter(function (item, index, array) {
            return obj.hasOwnProperty(typeof item + JSON.stringify(item))
                ? false
                : (obj[typeof item + JSON.stringify(item)] = true);
        });
    },

    /**
     * 去除前后空格
     *
     * @param {string} x
     */
    trim(x) {
        if (typeof x === 'string') {
            if (String.trim) {
                return x.trim();
            }
            return x.replace(/^\s+|\s+$/gm, '');
        }
        return x;
    },

    formatDate(time, fmt = 'yyyy-MM-dd HH:mm:ss') {
        let date = time === undefined ? new Date() : new Date(time);
        date = typeof date === 'number' ? new Date(date) : date;
        const obj = {
            y: date.getFullYear(), // 年份，注意必须用getFullYear
            M: date.getMonth() + 1, // 月份，注意是从0-11
            d: date.getDate(), // 日期
            q: Math.floor((date.getMonth() + 3) / 3), // 季度
            w: date.getDay(), // 星期，注意是0-6
            H: date.getHours(), // 24小时制
            h: date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 12小时制
            m: date.getMinutes(), // 分钟
            s: date.getSeconds(), // 秒
            S: date.getMilliseconds(), // 毫秒
        };
        const week = ['天', '一', '二', '三', '四', '五', '六'];
        for (const i in obj) {
            if (obj[i] !== undefined) {
                fmt = fmt.replace(new RegExp(`${i}+`, 'g'), (m) => {
                    let val = `${obj[i]}`;
                    if (i === 'w') {
                        return (m.length > 2 ? '星期' : '周') + week[val];
                    }
                    for (let j = 0, len = val.length; j < m.length - len; j++) {
                        val = `0${val}`;
                    }
                    return m.length === 1 ? val : val.substring(val.length - m.length);
                });
            }
        }
        return fmt;
    },

    changeUser(id) {
        return new Promise(async (resolve, reject) => {
            await Apis.company.activeCompany(id);

            await store.dispatch('initQuser');
            await store.dispatch('initUserPrivilege');
            await store.dispatch('initModulePrivilege');
            await store.dispatch('initUserExpiredInfo');
            resolve(true);
        });
    },

    /**
     * 获取时间描述
     *
     * @param {*} dateStr 时间字符串
     * @returns '几秒前' | '今天' | '昨天' | dateStr
     */
    getTimeDescribe(dateStr) {
        let copyStr = dateStr;
        if (copyStr && typeof copyStr === 'string') {
            copyStr = copyStr.replace(/-/g, '/');
        }
        const time = dateStr.split(' ')[1];
        const date = new Date(copyStr);
        const currentDate = new Date().getDate();
        if ((Date.now() - date.getTime()) / 1000 < 60) {
            return '几秒前';
        } else if (currentDate - date.getDate() === 0) {
            return `今天 ${time.substr(0, 5)}`;
        } else if (currentDate - date.getDate() === 1) {
            return `昨天 ${time.substr(0, 5)}`;
        }
        return dateStr.substr(0, dateStr.length - 3);
    },
    /**
     * 联系方式马赛克
     *
     * @param contact
     */
    showContact(contact) {
        if (!contact) {
            return;
        }
        const PHONE_RE_LIST = [
            /^(?:\+?(86)[\s-]?)?(1\d{10})$/, // 大陆号码：区号86，1开头的十一位数字
            /^\+?(852)[\s-]?([569]\d{7})$/, // 香港号码：区号852，5,6,9开头的八位数字
            /^\+?(886)[\s-]?(0?9\d{8})$/, // 台湾号码：区号886，09开头的十位数字（0可能不存在）
            /^\+?(853)[\s-]?6\d{7}$/, // 澳门号码：区号853，6开头后面跟7位数字
        ];
        if (validator.isEmail.test(contact)) {
            let emailInfo = contact.split('@');
            let emailContent = emailInfo[0];
            let len = emailContent.length;
            let hideStr = '';
            /*
             *   邮箱格式：
             *   长度大于或等于6，保留前2位和后2位，中间*号处理，例如：qy******ue@163.com
             *   长度大于或等于4小于6，保留前1位和后1位，中间*号处理，例如：q***e@qq.com
             *   长度小于4，保留第1位，其余*号处理，例如：q**@gmaile.com
             */
            if (len >= 6) {
                hideStr = emailContent.substring(0, 2) + '*'.repeat(len - 4) + emailContent.substr(-2);
            } else if (len >= 4 && len < 6) {
                hideStr = emailContent.substring(0, 1) + '*'.repeat(len - 2) + emailContent.substr(-1);
            } else {
                hideStr = emailContent.substring(0, 1) + '*'.repeat(len - 1);
            }
            return `${hideStr}@${emailInfo[1]}`;
        } else if (PHONE_RE_LIST.some((re) => re.test(contact))) {
            if (!contact.includes(' ')) {
                // 大陆手机号格式：不展示区号，中间4位用*代替
                return contact.substring(0, 3) + '*'.repeat(4) + contact.substr(-4);
            }
            let areaCode = `${contact.split(' ')[0]} `;
            let content = contact.split(' ')[1];
            // 非大陆手机号格式：展示除区号部分的前3位后两位，其他的用*代替
            let repeatTimes = content.length - 5;
            return areaCode + content.substring(0, 3) + '*'.repeat(repeatTimes) + content.substr(-2);
        }
        return contact;
    },

    /**
     * 获取日期 patern
     *
     * @param {string} val
     * @param {boolean} needFormat
     * @returns {string}
     */
    getPattern(val, needFormat) {
        const currentPattern = Object.entries(formatTypes).filter((v) => v.includes(val));
        if (currentPattern.length !== 1) {
            return needFormat ? 'yyyy-MM-dd' : 'HYPHEN';
        }

        return currentPattern[0][+needFormat];
    },

    /**
     * 单位转换：毫米转化为px
     *
     * @param {number} value
     */
    mmToPx(value) {
        return +((value * 96) / 25.4).toFixed(8);
    },

    /**
     * 序列化url
     *
     * @example serializeUrl({a: 1, b: 2})
     * @param query
     * @param coverSameParam
     * @param urlString
     * @returns {window.location.href}?a=1&b=2
     * @param {*} query：序列化参数对象 coverSameParam：若当前url存在相同参数，是否覆盖，默认不覆盖
     */
    serializeUrl(query = {}, coverSameParam = false, urlString) {
        const url = urlString ?? decodeURIComponent(window.location.href);
        const queryReg = /\?{1}[A-Za-z0-9\u4e00-\u9fa5_\.\+\-\/\?=&:%]*/g;
        let queryStr = '',
            queryArr = [];
        if (url.match(queryReg)) {
            let arr = url.match(queryReg)?.[0].split('?')[1];
            queryArr = arr?.split('&') ?? [];
        }
        Object.keys(query).forEach((q) => {
            let index = 0;
            let queryItem = queryArr.find((item, i) => {
                let paramKey = item.split('=')[0];
                index = i;
                return paramKey === q;
            });
            // 当前链接已存在相同参数，根据配置是否删除
            if (!!queryItem && coverSameParam) {
                queryArr.splice(index, 1);
            }
            if (!queryItem || coverSameParam) {
                queryArr.push(`${q}=${query[q]}`);
            }
        });
        queryStr = `?${queryArr.join('&')}`;
        return `${url.split('?')[0]}${queryStr}`;
    },
};
export default CommonUtils;
