const DOMUtils = {
    /**
     * 元素是否包含需要查询的class
     *
     * @param {*} el  当前DOM
     * @param {*} cls  class名称
     */
    hasClass(el, cls) {
        if (!el || !cls) return false;
        if (cls.indexOf(' ') !== -1) throw new Error('className should not contain space.');
        if (el.classList) {
            return el.classList.contains(cls);
        }
        return ` ${el.className} `.includes(` ${cls} `);
    },

    /**
     * 添加class
     *
     * @param {*} el DOM对象
     * @param {*} cls class名称
     */
    addClass(el, cls) {
        if (!el) return;
        let curClass = el.className;
        let classes = (cls || '').split(' ');

        for (let i = 0, j = classes.length; i < j; i++) {
            let clsName = classes[i];
            if (!clsName) continue;

            if (el.classList) {
                el.classList.add(clsName);
            } else if (!DOMUtils.hasClass(el, clsName)) {
                curClass += ` ${clsName}`;
            }
        }
        if (!el.classList) {
            el.className = curClass;
        }
    },

    /**
     * 移除class
     *
     * @param {*} el Dom对象
     * @param {*} cls class名称
     */
    removeClass(el, cls) {
        if (!el || !cls) return;
        let classes = cls.split(' ');
        let curClass = ` ${el.className} `;

        for (let i = 0, j = classes.length; i < j; i++) {
            let clsName = classes[i];
            if (!clsName) continue;

            if (el.classList) {
                el.classList.remove(clsName);
            } else if (DOMUtils.hasClass(el, clsName)) {
                curClass = curClass.replace(` ${clsName} `, ' ');
            }
        }
        if (!el.classList) {
            el.className = trim(curClass);
        }
    },

    /**
     * 递归查找点击对象及其父元素是否有特定的某个class
     *
     * @param {*} elem Dom对象
     * @param {*} cls class名称
     * @param className
     */
    $findParentNodeWithClass(elem, className) {
        if (!elem) {
            return null;
        }
        if (DOMUtils.hasClass(elem, className)) {
            return elem;
        }
        return DOMUtils.$findParentNodeWithClass(elem.parentNode, className);
    },
};
export default DOMUtils;
