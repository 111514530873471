import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';

import store from '@/store';
import '@/global';
// 组件
import './components/index';
// -------------- element-ui -----------------
import '@/packages/element-ui/index';
// -------------- element-ui -----------------
import '@/packages/mitra-design/index';

import router from './router/index';

/** 工具方法  */
import Utils from '../assets/utils/index';

import '../assets/fonts/pc/iconfont.js';

Vue.prototype.$Utils = Utils;

/** 全局组件注册 */
import SvgIcon from '@pc/components/business/svg-icon/index.vue';

Vue.component('svg-icon', SvgIcon);

import { i18n } from '@/i18n';

new Vue({
    i18n,
    router,
    store,
    created() {
        window.$t = this.$t.bind(this);
    },
    render: (h) => h(App),
}).$mount('#app');
window.Vue = Vue;
window.router = router;
