import Vue from 'vue';

// 导入组件
import { ProFilters, ProTable } from '@mitra-design/pro-components';
import MitraDesign from '@mitra-design/web';

// 引入组件样式
import '@mitra-design/pro-components/es/style.css';
import '@mitra-design/web/es/style.css';

Vue.use(ProFilters);
Vue.use(ProTable);
Vue.use(MitraDesign);
